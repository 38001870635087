export const ERROR_MONITORING_TICKETS_URL = '/errormonitoring/tickets';
export const ERROR_MONITORING_TICKET_URL = '/errormonitoring/ticket';
export const ERROR_MONITORING_ERRORS_URL = '/errormonitoring/errors';

export const USERS_GET_PV_INSTALLER_USERS_ATTACHED_TO_VIEWER_URL = '/users/get-pv-installer-users-attached-to-viewer';
export const USERS_GET_NOT_ATTACHED_TO_VIEWER_END_USERS_URL = '/users/get-not-attached-to-viewer-end-users';
export const USERS_GET_ATTACHED_TO_VIEWER_END_USERS_URL = '/users/get-attached-to-viewer-end-users';
export const USERS_GET_NOT_SUPPORTED_END_USERS_URL = '/users/get-not-supported-end-users';
export const USERS_GET_PROP_MGMT_END_USERS_URL = '/users/get-property-management-end-users';

export const USERS_REMOVE_USERS_FROM_SUPPORT_URL = '/users/remove-users-from-support';
export const USERS_REMOVE_VIEWER_FROM_USERS_URL = '/users/remove-viewer-from-users';
export const USERS_ATTACH_VIEWER_TO_USERS_URL = '/users/attach-viewer-to-users';
export const USERS_EXPORTS_PV_INSTALLERS_URL = '/users/exports/pv-installers';
export const USERS_EXPORTS_OEMS = '/export/oem';
export const USERS_SOLAR_MANAGERS_USERS_URL = '/users/solar-managers-users';
export const USERS_ADD_USERS_TO_PROP_MGMT_URL = '/users/add-users-to-property-management';
export const USERS_PV_INSTALLER_USERS_URL = '/users/pv-installer-users';
export const USERS_SUPPORT_CONTRACT_URL = '/users/support-contract';
export const USERS_URL = '/users/end_user';
export const ADMIN_URL = '/users/solar_admin';
export const USERS_SOLAR_ADMIN_URL = '/users/solar-admin';
export const USERS_EMPLOYEE_URL = '/users/employee';
export const USERS_BACKDOOR_URL = '/users/backdoor';
export const USERS_VIEWERS_URL = '/users/viewers';
export const USERS_VIEWER_URL = '/users/viewer';
export const USERS_PROP_MGMT_URL = '/users/property_management';

export const USERS_DOCUMENTATION_FILES_URL = '/users-documentation/files';
export const USERS_DOCUMENTATION_URL = '/users-documentation';

export const USERS_SUB_USER_SUB_METER_URL = '/users/subuser/submeter';
export const USERS_SUB_USER_URL = '/users/subuser';
export const SUB_USER_URL = '/users/sub_user';

export const USER_GET_LICENSE_LIST_URL = '/user/get-license-list';
export const USER_SETTINGS_URL = '/user/settings';
export const USER_TARIFF_SETTINGS_URL = '/settings/tariffs';
export const USER_HOUSE_SETTINGS_URL = '/settings/house';
export const USER_UPDATE_URL = '/user/update';
export const USER_ME_URL = '/user/me';

export const ACCOUNT_REQUEST_DEBUG_LOG_URL = '/account/request/debug-log';
export const ACCOUNT_REQUEST_LOG_URL = '/account/request/log';
export const ACCOUNT_IP_SCAN_URL = '/account/ip/scan';
export const ACCOUNT_IP_LIST_URL = '/account/ip/list';

export const AUTH_FORGOT_PASSWORD_URL = '/auth/forgot-password';
export const AUTH_RESET_PASSWORD_URL = '/auth/reset-password';
export const SENSORS_AUTH_OAUTH2_URL = '/sensors/auth/oauth2';
export const V1_AUTH_BASIC_LOGIN_URL = '/v1/auth/basic/login';
export const V1_AUTH_OAUTH_LOGIN_URL = '/v1/auth/oauth/login';
export const AUTH_LOCAL_URL = '/auth/local';

export const SIGNUP_RESEND_VERIFY_URL = '/signup/resend/verify';
export const SIGNUP_PV_INSTALLER_URL = '/signup/pv-installer';
export const SIGNUP_CHANGE_EMAIL_URL = '/signup/change/email';
export const SIGNUP_COMPLETE_URL = '/signup/complete';
export const SIGNUP_VERIFY_URL = '/signup/verify';
export const SIGNUP_USER_URL = '/signup/user';

export const GATEWAY_TURN_ON_LOAD_MANAGEMENT_URL = '/gateway/turn-on-load-managment';
export const GATEWAY_REBOOT_SERVICE_LOGS_URL = '/gateway/reboot-service-logs';
export const GATEWAY_INSTALLATION_STATUS_URL = '/gateway/installation-status';
export const GATEWAY_USER_ACTIVITY_LOGS_URL = '/gateway/user-activity-logs';
export const GATEWAYS_HARDWARE_URL = '/gateways/hardware';
export const GATEWAY_LICENSE_URL = '/gateway/license';
export const GATEWAY_LIST_URL = '/gateway/list';
export const GATEWAY_URL_DELETE = '/gateway';

export const OEM_CONNECTED_PV_INSTALLERS_URL = '/oem/connected-pv-installers';
export const OEM_CONNECTED_END_USERS_URL = '/oem/connected-end-users';
export const OEM_CONNECTED_OEMS_URL = '/oem/connected-oems';
export const OEM_PV_INSTALLER_URL = '/oem/pv-installer';
export const OEM_URL = '/oem';
export const NEW_OEM_URL = '/users/oem';
export const OEM_EMPLOYEES_URL = '/users/oem-employee';

export const PV_INSTALLER_URL = '/users/pv_installer';

export const SENSORS_SET_DEVICE_ID_URL = '/sensors/set-device-id';
export const SENSOR_DATA_SETTINGS_URL = '/sensordata/settings';
export const SENSORS_SUB_METER_URL = '/sensors/sub-meter';
export const SENSORS_IDENTIFY_URL = '/sensors/identify';
export const SENSOR_TYPES_PART_URL = '/sensor-types';
export const SENSOR_TAGS_URL = '/sensortags';
export const SENSORS_URL = '/sensors';

export const DATA_EXPORT_GATEWAYS_INFO_URL = '/data/export-gateways-info';
export const DATA_EXPORT_GATEWAY_DATA_URL = '/data/export-gateway-data';
export const DATA_EXPORT_USERS_INFO_URL = '/data/export-users-info';
export const DATA_SENSORS_LOAD_DATA_URL = '/data/sensors/load-data';
export const DATA_SENSORS_URL = '/data/sensors';

export const FIRMWARE_UPDATE_URL_DELETE = '/firmware-update';
export const FIRMWARE_AVAILABLE_URL = '/firmware/avaliable';
export const FIRMWARE_UPDATE_URL = '/firmware/update';

export const SETTING_PROFILE_PV_INSTALLER_USERS_URL = '/setting-profile/pv-installer-users';
export const SETTING_PROFILE_VIEWERS_URL = '/setting-profile/viewers';
export const SETTING_PROPERTY_MANAGEMENT_PROFILE__URL = '/setting-profile/property-management';
export const SETTING_PROFILE_OEM_URL = '/setting-profile/oem';

export const MAINTENANCE_MESSAGE_URL = '/maintenance-message';
export const EMAIL_VERIFY_URL = '/email-verify';
export const LOADGUARDS_URL = '/loadguards';
export const OVERVIEW_URL = '/overview';
export const ZOHO_REDIRECT_URL = '/zoho/redirect';
export const PROVIDERS_URL = '/user/settings/providers';
export const PROVIDER_OAUTH_URL = '/user/settings/providers/oauth2/init';
export const INSTALLER_DASHBOARD_URL = '/installations-metrics';
export const DEVICE_STATISTICS_URL = '/device-statistics/sum';
export const THERMONDO_SAML_AUTH_REQUEST_DATA_URL = '/auth/thermondo/saml/auth-request-data';
